<template>
  <div class="wrapperDiv">
    <h1>
      <span>4<i class="far fa-frown"></i>4</span>
    </h1>
    <h5>{{ $t("NOT FOUND") }}</h5>
    <router-link to="/" style="color:#7295fb">
      {{ $t("Back to home") }}
    </router-link>
  </div>
</template>

<script>
export default {
  name: "Component404"
};
</script>

<style lang="css" scoped>
.wrapperDiv {
  text-align: center;
  margin-top: 200px;
}
h1 {
  font-size: 250px;
  color: #7295fb;
}
h1 .fa-frown {
  font-size: 190px;
  margin: 0 20px;
}
h1 span {
  border-bottom: 1px solid;
}
h1,
h3 {
  margin-bottom: 0;
  font-family: sans-serif;
}
h3 {
  color: #676767;
}
img {
  color: #7295fb;
}
</style>
